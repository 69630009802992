<template>
  <div class="mobile-sidebar__root">
    <div
      :class="{'open':isOpen}"
      class="mobile-sidebar"
    >
      <slot />
    </div>
    <div
      v-if="isShowBackground"
      class="bg"
      @click="closeSidebar"
    />
  </div>
</template>

<script setup lang="ts">
import {togglePageScroll} from "~/utils/scroll/togglePageScroll";

const props = withDefaults(defineProps<{
	isOpen: boolean,
	offsetTop?: string,
	zIndex?: string | 'inherit' | '-1' | '5' | '10' | '20' | '25',
	width?: string | '10%' | '80%',
	bgZIndex?: string | 'inherit' | '-1' | '5' | '10' | '20' | '25',
	isShowBg?: boolean,
	bgColor?: string,
}>(), {
  isOpen: false,
  bgColor: '#8b8b8bb3',
  bgZIndex: '-1',
  offsetTop: '96px',
  zIndex: 'inherit',
  isShowBg: true,
  width: '100%'
});

watch(() => props.isOpen, (newValue) => {
  togglePageScroll(newValue === false)
});
const { onMobileSidebarHandler } = useMobileMenu();
const closeSidebar = () => {
  onMobileSidebarHandler();
};

const isShowBackground = computed(() => (props.isOpen && props.isShowBg));
</script>

<style lang="scss">
@import "@/assets/scss/media";

.mobile-sidebar {
	display: none;
	width: v-bind(width);
	padding: 0;
}

@include media('lg') {
	.mobile-sidebar__root {
		position: relative;

		& .bg {
			position: fixed;
			z-index: v-bind(bgZIndex);
			background: v-bind(bgColor);
			inset: v-bind(offsetTop) 0 0 0;
		}

    &.transparent {
      background: white;
    }
	}

	.mobile-sidebar {
		display: flex;
		flex-direction: column;
		position: fixed;
		width: v-bind(width);
		inset: v-bind(offsetTop) 0 0 0;
		transition: all .3s linear;
		transform: translateX(-110%);
		z-index: v-bind(zIndex);
		overflow: hidden;

		&.open {
			transform: translateX(0);
			transition: all .3s linear;
		}

	}
}

</style>
